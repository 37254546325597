import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  refreshAsync,
  selectScore,
  selectActivity,
  selectLeague,
  selectFeed
} from './scoreSlice';
import styles from './Score.module.css';


export function Score() {
  const score = useSelector(selectScore);
  console.log('score - '+score)
  const activities = useSelector(selectActivity);
  const league = useSelector(selectLeague);
  const feed = useSelector(selectActivity);
  const dispatch = useDispatch();
  var intervalSet = false;

  const getScore = () => {
    dispatch(refreshAsync(league))
  }

  useEffect(() => {
    console.log("in useEffect")
    if(intervalSet === false) {
      getScore();
      console.log("setting interval")
      setInterval(getScore, 30000)
      intervalSet = true;
    }
  });

  return (
    <div className="container-fluid">
    <div className="header">
      <div className="text-center">
        <h1>Total Goals Scored</h1>
        <h1>{score}</h1>
      </div>
    </div>
    <div className="col-md-12 activity-feed">
      {feed.map((activity, index) => (
        <div className="activity-entry" key={index}>
          <div className="col-md-4"></div>
          <div className="col-md-1">
            <img src={activity.picture} />
          </div>
          <div className="col-md-4">
            <p>{activity.text}</p>
          </div>
          <div className="col-md-2"></div>
        </div>
      ))}
    </div>
  </div>
  );
}

{/* <div className="activity-feed">
      {feed.map((activity, index) => (
        <div className="activity-entry" key={index}>
          <img src={activity.picture} />
          <p>{activity.text}</p>
        </div>
      ))}
    </div> */}