import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchScores } from './scoreAPI';

const initialState = {
  games: {},
  activity: [],
  totalScore: 0,
  timeLeft: 0,
  timeName: "innings",
  league: 'mlb',
  gamesFor: "",
  lastUpdated: "never",
  status: "idle"
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(refreshAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const refreshAsync = createAsyncThunk(
    'score/fetchScores',
    async (leauge) => {
      const response = await fetchScores(leauge);
      // The value we return becomes the `fulfilled` action payload
      // console.log(response)
      return response.dates;
    }
  );
  
  export const scoreSlice = createSlice({
    name: 'score',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
      refresh: (state) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        console.log("refreshing")
        state.value += 1;
      },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
      builder
        .addCase(refreshAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(refreshAsync.fulfilled, (state, action) => {
          var now = new Date();
          var time = now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
          
          var newGameState = {};
          // console.log(action.payload)
          var currentGameState = action.payload[0].games;
          var totalScore = 0;
          console.log(state.games)
          currentGameState.forEach(function(game) {
            const gamePk = game.gamePk.toString();
            console.log(gamePk in state.games)
            console.log(game.status.detailedState)
            if (gamePk in state.games && game.status.detailedState === "In Progress"){
              console.log("game in progress")
              var oldGameState = state.games[game.gamePk]
              if (oldGameState.teams.away.score < game.teams.away.score){
                console.log("away team scored")
                const scoreDiff = game.teams.away.score - oldGameState.teams.away.score
                if(scoreDiff > 1) {
                  state.activity.unshift({
                    "picture": "https://midfield.mlbstatic.com/v1/team/" + game.teams.away.team.id + "/spots/128",
                    "text": game.teams.away.team.name + " scored " + scoreDiff + " runs!"
                  });
                }
                else {
                  state.activity.unshift({
                    "picture": "https://midfield.mlbstatic.com/v1/team/" + game.teams.away.team.id + "/spots/128",
                    "text": game.teams.away.team.name + " scored " + scoreDiff + " run!"
                  });
                }
                
              }

              if (oldGameState.teams.home.score < game.teams.home.score){
                console.log('home team scored')
                const scoreDiff = game.teams.home.score - oldGameState.teams.home.score
                if(scoreDiff > 1) {
                  state.activity.unshift({
                    "picture": "https://midfield.mlbstatic.com/v1/team/" + game.teams.home.team.id + "/spots/128",
                    "text": game.teams.home.team.name + " scored " + scoreDiff + " goals!"
                  });
                }
                else {
                  state.activity.unshift({
                    "picture": "https://midfield.mlbstatic.com/v1/team/" + game.teams.home.team.id + "/spots/128",
                    "text": game.teams.home.team.name + " scored " + scoreDiff + " goals!"
                  });
                }
              }
            }
            newGameState[gamePk] = game;
            if(Number.isInteger(game.teams.away.score) && Number.isInteger(game.teams.home.score)){
              totalScore += game.teams.away.score + game.teams.home.score
            }

            
          });
          state.gamesFor = action.payload[0].date;
          state.games = newGameState;
          state.totalScore = totalScore;
          state.status = 'idle';
          state.lastUpdated = time;
          if (state.activity.length == 0) {
            state.activity.unshift({
              // MLB
              // picture: "https://midfield.mlbstatic.com/v1/team/158/spots/128",
              // NHL
              picture: "https://midfield.mlbstatic.com/v1/team/158/spots/128",
              text: "Welcome to the Major Salami!"
            })
          }
        })
        .addCase(refreshAsync.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        });
    },
  });
  
  export const { refresh } = scoreSlice.actions;
  
  // The function below is called a selector and allows us to select a value from
  // the state. Selectors can also be defined inline where they're used instead of
  // in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
  export const selectScore = (state) => state.score.totalScore;

  export const selectActivity = (state) => state.score.activity;
  export const selectFeed = (state) => state.score.activity.reverse();
  export const selectLeague = (state) => state.score.league;
  
  // We can also write thunks by hand, which may contain both sync and async logic.
  // Here's an example of conditionally dispatching actions based on current state.
  // export const incrementIfOdd = (amount) => (dispatch, getState) => {
  //   const currentValue = selectCount(getState());
  //   if (currentValue % 2 === 1) {
  //     dispatch(incrementByAmount(amount));
  //   }
  // };
  
  export default scoreSlice.reducer;
  