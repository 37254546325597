import React from 'react';
import logo from './logo.svg';
import { Score } from './features/score/Score';
import './App.css';

function App() {
  return (
        <Score />
  );
}

export default App;
